import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Autocomplete,
  Backdrop,
  Button,
  Modal,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../loader/Loader";
import axios from "axios";

const ZOHO = window.ZOHO;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  minHeight: 200,
  transform: "translate(-50%, -50%)",
  minWidth: 660,
  bgcolor: "background.paper",
  // borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

const SingleModule = ({
  cronRunningModules,
  handleSubmit,
  key,
  blockData,
  handleEdit,
  handleDelete,
  edit_custom_module,
  custom_action,
}) => {
  // const [orgIdIndividual, setOrgIdIndividual] = useState();
  // const [zohoApiKeyIndividual, setZohoApiKeyIndividual] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedModule, setSelectedModule] = useState();
  const [customViews, setCustomViews] = useState([]);
  const [selectedViews, setSelectedViews] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = (module) => {
    setSelectedModule(module);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [showBtn, setShowBtn] = React.useState(false);

  const handleMouseEnter = () => {
    setShowBtn(true);
  };

  const handleMouseLeave = () => {
    setShowBtn(false);
  };

  // useEffect(() => {
  //   if (orgId) {
  //     setOrgIdIndividual(orgId);
  //   }
  //   if (zohoApiKey) {
  //     setZohoApiKeyIndividual(orgId);
  //   }
  // }, [orgId, zohoApiKey]);

  useEffect(() => {
    if (!selectedModule?.module_name) {
      return;
    }
    setLoading(true);

    ZOHO.CRM.META.getCustomViews({ Entity: selectedModule.module_name }).then(
      function (data) {
        setCustomViews(data?.custom_views);
        setLoading(false);
      }
    );
  }, [selectedModule]);

  // const handleSubmit = async () => {
  //   
  //   return;
  //   try {
  //     const resp = await axios.request({
  //       url: `https://api.v1.admin.easy-pluginz.com/utils/cronjobs`,
  //       method: "POST",
  //       headers: {
  //         orgid: orgId, // Org ID
  //         apikey: zohoApiKey, // API KEy
  //         connname: "easyaddressautocomplete", // Conn Name
  //       },
  //       data: {
  //         reqUrl: encodeURIComponent("www.google.com"),
  //         reqParams: {},
  //         reqHeaders: {},
  //         reqBody: { blockData: selectedModule, cv_id: selectedViews?.id },
  //         reqType: "GET",
  //       },
  //     });
  //     
  //   } catch (error) {
  //     
  //   }
  // };

  return (
    <Grid
      item
      xs={12}
      md={custom_action ? 0 : 4}
      lg={custom_action ? 0 : 3}
      key={key}
    >
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          p: 2,
          boxShadow:
            "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",

          borderTop: "2px solid #42A5F5",
          a: {
            opacity: 0,
          },
          "&:hover": {
            a: {
              opacity: 1,
            },
          },
        }}
      >
        <Typography
          sx={{ fontFamily: "Lato", fontWeight: 600, fontSize: "20px" }}
        >
          {blockData.setting_schema.moduleName.plural_label}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.6)",
            py: "1rem",
          }}
        >
          Module's Address Field Mapping. Click Edit to change Existing Field
          Mapping and Address Block Informations
        </Typography>
        <Typography sx={{ display: "flex", justifyContent: "flex-end" }}>
          {/* {!edit_custom_module && (
          <Link
            onClick={() => handleDelete(blockData)}
            underline="hover"
            sx={{
              cursor: "pointer",
              mr: 1.5,
              color: "red",
              textTransform: "capitalize",
              fontFamily: "Lato",
              fontWeight: 500,
              fontSize: "13px",
              // width: "4rem",
              // bgcolor: "#E5E4E2",
              // p: ".25rem .5rem",
              borderRadius: "2rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Delete
          </Link>
        )} */}
          {/* <Link
          sx={{ tex }}
          // underline="hover"
        > */}

          <Box
            sx={{
              height: 30,
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {/* {showBtn &&
              (cronRunningModules.includes(blockData.module_name) ? (
                <Button
                  variant="outlined"
                  sx={{
                    // color: "#42A5F5",
                    cursor: "pointer",
                    textTransform: "capitalize",
                    fontFamily: "Lato",
                    fontWeight: 500,
                    fontSize: "13px",
                    // width: "4rem",
                    // bgcolor: "#E5E4E2",
                    // p: ".25rem .5rem",
                    borderRadius: "2rem",

                    // justifyContent: "center",
                  }}
                >
                  {" "}
                  Stop
                </Button>
              ) : (
                <Button
                  onClick={() => handleOpen(blockData)}
                  variant="outlined"
                  sx={{
                    // color: "#42A5F5",
                    cursor: "pointer",
                    textTransform: "capitalize",
                    fontFamily: "Lato",
                    fontWeight: 500,
                    fontSize: "13px",
                    // width: "4rem",
                    // bgcolor: "#E5E4E2",
                    // p: ".25rem .5rem",
                    borderRadius: "2rem",

                    // justifyContent: "center",
                  }}
                >
                  {" "}
                  Historical Data
                </Button>
              ))} */}

            {showBtn && (
              <Button
                onClick={() => handleEdit(blockData)}
                variant="outlined"
                sx={{
                  // color: "#42A5F5",
                  cursor: "pointer",
                  textTransform: "capitalize",
                  fontFamily: "Lato",
                  fontWeight: 500,
                  fontSize: "13px",
                  // width: "4rem",
                  // bgcolor: "#E5E4E2",
                  // p: ".25rem .5rem",
                  borderRadius: "2rem",

                  // justifyContent: "center",
                }}
              >
                {" "}
                Edit
              </Button>
            )}
          </Box>
          {/* </Link> */}
        </Typography>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {loading ? (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <Loader />
            </Backdrop>
          ) : (
            // <Box
            //   sx={{
            //     display: "flex",
            //     justifyContent: "center",
            //     alignItems: "center",
            //     height: 600,
            //   }}
            // >
            //   <Typography
            //     sx={{
            //       fontSize: 18,
            //       fontWeight: "bold",
            //     }}
            //   >
            //     Please wait while fetching data...
            //   </Typography>
            // </Box>
            <Box>
              <Typography sx={{ fontWeight: "bold", mt: 1, mb: 3 }}>
                Please Select Custom View
              </Typography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={customViews}
                getOptionLabel={(option) => option?.display_value}
                onChange={(_, data) => {
                  if (data) {
                    setSelectedViews(data);
                  } else {
                    setSelectedViews();
                  }
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Custom Views"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  sx={{ mr: 2, width: 100 }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!selectedViews?.id}
                  onClick={() =>
                    handleSubmit(selectedModule, selectedViews?.id)
                  }
                  variant="contained"
                  sx={{ width: 100 }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </Grid>
  );
};

export default function AddressBlockTables({
  cronRunningModules,
  handleSubmit,
  fetchedBlocks,
  handleEdit,
  handleDelete,
  edit_custom_module,
  custom_action,
}) {
  return (
    <Box>
      <Grid container spacing={custom_action ? 0 : 2}>
        {fetchedBlocks?.map((blockData, blockIndex) => (
          <SingleModule
            cronRunningModules={cronRunningModules}
            handleSubmit={handleSubmit}
            key={blockIndex}
            custom_action={custom_action}
            blockData={blockData}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            edit_custom_module={edit_custom_module}
          />
        ))}
      </Grid>
    </Box>
  );
}
