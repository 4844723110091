import React, { useState, useEffect } from "react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";

import CustomAction from "../components/custom_action/index";
import { LoadScript } from "@react-google-maps/api";

import Loader from "../components/loader/Loader";

const ZOHO = window.ZOHO;

const Index = () => {
  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [moduleInfo, setModuleInfo] = useState(null);
  const [modules, setModules] = useState([]);
  const [fetchedInfo, setFetchedInfo] = useState(null);
  const [show_settings_btn, set_show_settings_btn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetchedModules, setFetchedModules] = useState({});
  const [apiKey, setApiKey] = useState(null);

  const [dataCenterUrl, setDataCenterUrl] = useState("");
  const [zohoApiKey, setZohoApiKey] = useState();
  const [orgId, setOrgId] = useState();
  const [zapiKey, setZapiKey] = useState();

  const handleFetchedModules = (module) => {
    setFetchedModules((prev) => ({
      ...prev,
      ...module,
    }));
  };

  const fetchDataOfThatModule = async () => {
    // fetch related module setting schema
    const updateData = await axios.request({
      url: `https://api.easy-pluginz.com/admin/v2/db/planetscale/clients/settings?moduleApiName=${moduleInfo.module_name}`,
      method: "GET",
      headers: {
        orgid: orgId, // Org ID
        apikey: zohoApiKey, // API KEy
        connname: "easyaddressautocomplete", // Conn Name
      },
    });
    setFetchedInfo({
      ...updateData.data.data[0],
      setting_schema: JSON.parse(updateData.data.data[0].setting_schema),
    });
    set_show_settings_btn(false);
  };

  useEffect(() => {
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        const orgData = {
          apiKeys: [
            "easyaddressautocomplete__API_KEY",
            "easyaddressautocomplete__Organization_ID",
            "easyaddressautocomplete__ZAPI_Key",
            "easyaddressautocomplete__Data_Center_URL",
            "easyaddressautocomplete__Google_API_KEY",
          ],
        };
        const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);

        setDataCenterUrl(
          orgVariables?.Success?.Content
            ?.easyaddressautocomplete__Data_Center_URL?.value
            ? orgVariables?.Success?.Content
                ?.easyaddressautocomplete__Data_Center_URL?.value != "null"
              ? orgVariables?.Success?.Content
                  ?.easyaddressautocomplete__Data_Center_URL?.value
              : dataCenterUrl
            : dataCenterUrl
        );
        setZohoApiKey(
          orgVariables?.Success?.Content?.easyaddressautocomplete__API_KEY
            ?.value
        );
        setOrgId(
          orgVariables?.Success?.Content
            ?.easyaddressautocomplete__Organization_ID?.value
        );
        setZapiKey(
          orgVariables?.Success?.Content?.easyaddressautocomplete__ZAPI_Key
            ?.value
        );
        setApiKey(
          orgVariables?.Success?.Content
            ?.easyaddressautocomplete__Google_API_KEY?.value
        );

        const getAllModules = await ZOHO.CRM.META.getModules();

        const filteredModule = getAllModules.modules.filter((module) => {
          if (
            module.api_name === data.module ||
            module.module_name === data.module
          ) {
            return true;
          }
        })[0];

        setModuleInfo({
          module_name: data.module,
          plural_label: filteredModule.plural_label,
          data: { ...data, Entity: filteredModule.api_name },
          buttonPosition: data.ButtonPosition,
        });
        setZohoLoaded(true);
      });

      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);
  useEffect(() => {
    if (zohoLoaded && moduleInfo) {
      (async () => {
        try {
          // fetch related module setting schema
          const updateData = await axios.request({
            url: `https://api.easy-pluginz.com/admin/v2/db/planetscale/clients/settings?moduleApiName=${moduleInfo.module_name}`,
            method: "GET",
            headers: {
              orgid: orgId, // Org ID
              apikey: zohoApiKey, // API KEy
              connname: "easyaddressautocomplete", // Conn Name
            },
          });

          ZOHO.CRM.META.getModules().then(function (data) {
            const filteredModules = data.modules.filter((module) => {
              if (
                module.global_search_supported &&
                module.visible &&
                module.editable &&
                module.show_as_tab
              ) {
                return true;
              }
            });
            setModules(filteredModules);
          });

          //if there is no data for this module
          //that means show button for add setting so that user can create setting for this module
          if (!updateData.data.data) {
            ZOHO.CRM.META.getFields({ Entity: moduleInfo.module_name }).then(
              function (data) {
                const filteredFields = data.fields.filter((field) => {
                  if (field.data_type === "text") {
                    return true;
                  }
                });

                handleFetchedModules({
                  [moduleInfo.module_name]: filteredFields,
                });
              }
            );

            set_show_settings_btn(true);
            return;
          }

          setFetchedInfo({
            ...updateData.data.data[0],
            setting_schema: JSON.parse(updateData.data.data[0].setting_schema),
          });
        } catch (err) {
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [zohoLoaded, moduleInfo]);

  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Loader />
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
    );
  }
  return (
    apiKey && (
      <LoadScript
        googleMapsApiKey={apiKey}
        loadingElement={
          <div style={{ height: window.ZOHO_MODAL_HEIGHT }}>
            <Loader />
          </div>
        }
        libraries={["places"]}
      >
        <CustomAction
          ZOHO={ZOHO}
          api_key={apiKey}
          moduleInfo={moduleInfo}
          fetchedInfo={fetchedInfo}
          show_settings_btn={show_settings_btn}
          fetchedModules={fetchedModules}
          modules={modules}
          handleFetchedModules={handleFetchedModules}
          fetchDataOfThatModule={fetchDataOfThatModule}
          orgId={orgId}
          zohoApiKey={zohoApiKey}
        />
      </LoadScript>
    )
  );
};

export default Index;
