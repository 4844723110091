import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import { Box } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import NewTable from "./NewTable";
import Loader from "../../components/loader/Loader";

export default function ListView({ moduleInfo, fetchedInfo, apiKey, ZOHO }) {
  function customTrim(str, chars) {
    let start = 0;
    let end = str.length;

    while (start < end && chars.includes(str[start])) {
      start++;
    }

    while (end > start && chars.includes(str[end - 1])) {
      end--;
    }

    return str.slice(start, end);
  }
  const [loading, setLoading] = useState(true);
  const [row_data, set_row_data] = useState(null);
  const [head_cells, set_head_cells] = useState(null);

  const [error, setError] = useState(false);

  useEffect(() => {
    //if user selects more than 100 records then show error

    if (moduleInfo.data.EntityId.length > 100) {
      setLoading(false);
      return setError(true);
    }

    // Iterates all record id to get the info related to that record id
    const fetchRecordInfo = async (all_record_id) => {
      const requests = all_record_id.map(async (id) => {
        return ZOHO.CRM.API.getRecord({
          Entity: moduleInfo.module_name,
          RecordID: id,
        });
      });
      return Promise.all(requests); // Waiting for all the requests to get resolved.
    };
    const head_cells = [];
    fetchedInfo.setting_schema.blocks.forEach((block, block_index) => {
      const head_cell_info = {
        id: `block_${block_index}`,
        numeric: false,
        disablePadding: true,
        label: block.address_block_name,
      };
      head_cells.push(head_cell_info);
      head_cells.push({
        id: `block_${block_index}`,
        numeric: false,
        disablePadding: true,
        label: "Status",
      });
    });
    head_cells.unshift({
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
    });
    set_head_cells(head_cells);

    fetchRecordInfo(moduleInfo.data.EntityId)
      .then((all_record_info) => {
        return all_record_info.map((record_info) => record_info.data[0]);
      })
      .then((all_record_info) => {
        //we will use this data to generate table rows
        const row_data = {};

        // populate necessary data to row_data Eg:
        // 4731441000006079021:{
        //   blocks:{
        //    0:{
        //      full_address:"259, East Erie Street,Chicago,IL,60611,United States",
        //            status:''
        //    },
        //    1:{
        //      full_address:"26, Federal Plaza,United States,New York,NY,10278,United States",
        //      status:''
        //    }
        //   },
        //   name:'tanveer',
        //   id:'4731441000006079021'
        // }

        //populate full_address related to that block to each record
        all_record_info.forEach((record) => {
          const data = {};
          const record_id = record.id;

          //iterate through blocks
          fetchedInfo.setting_schema.blocks.forEach((block, blockIndex) => {
            const { fieldMapping } = block;

            let fullAddressParts = [];
            [
              "address_1",
              "address_2",
              "city",
              "state",
              "zip",
              "country",
              "latitude",
              "longitude",
            ].forEach((partKey) => {
              if (
                fieldMapping?.[partKey]?.selected_field?.api_name &&
                record?.[fieldMapping?.[partKey]?.selected_field?.api_name] &&
                partKey !== "latitude" &&
                partKey !== "longitude"
              ) {
                fullAddressParts.push(
                  record?.[fieldMapping?.[partKey]?.selected_field?.api_name]
                );
              }
            });

            //populate full address to data related to that block

            const blockData = {};
            blockData.full_address = fullAddressParts.join(",");
            blockData.status =
              fullAddressParts.join(",") === "" ? "No Data" : "";
            data.blocks = {
              ...data.blocks,
              [blockIndex]: blockData,
            };
          });
          //populate name to data
          let Name;
          if (
            moduleInfo.module_name === "Leads" ||
            moduleInfo.module_name === "Contacts"
          ) {
            Name = "Full_Name";
          } else if (moduleInfo.module_name === "Accounts") {
            Name = "Account_Name";
          } else if (moduleInfo.module_name === "Deals") {
            Name = "Deal_Name";
          } else if (moduleInfo.module_name === "Products") {
            Name = "Product_Name";
          } else if (moduleInfo.module_name === "Campaigns") {
            Name = "Campaign_Name";
          } else if (moduleInfo.module_name === "Vendors") {
            Name = "Vendor_Name";
          } else {
            Name = "Name";
          }
          data.name = record[Name];
          //populate id to data
          data.id = record_id;

          //populate data to row_data
          row_data[record_id] = data;
        });

        setLoading(false);
        set_row_data(row_data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Loader />
      </Backdrop>
    );
  }

  return (
    <>
      {error && (
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Alert severity="error">
            You can not select more than 100 records!
          </Alert>
        </Box>
      )}

      {head_cells && row_data && (
        <NewTable
          head_cells={head_cells}
          row_data={row_data}
          set_row_data={set_row_data}
          fetchedInfo={fetchedInfo}
          googleApiKey={apiKey}
          ZOHO={ZOHO}
        />
      )}
    </>
  );
}
