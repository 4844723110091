import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import latLongNotFound from "../../latLongNotFound.png";

import { Backdrop, Box } from "@mui/material";
import GoogleMap from "./Components/GoogleMap/GoogleMap";
import Loader from "../../components/loader/Loader";

export default function ListViewWithoutRecord({
  moduleInfo,
  listViewWithoutRecordInfo,
  dataCenterUrl,
  fetchedInfo,
  ZOHO,
  apiKey,
  zapiKey,
}) {
  function customTrim(str, chars) {
    let start = 0;
    let end = str.length;

    while (start < end && chars.includes(str[start])) {
      start++;
    }

    while (end > start && chars.includes(str[end - 1])) {
      end--;
    }

    return str.slice(start, end);
  }
  const [blockData, setBlockData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const block_settings = {};

      const setting_schema = fetchedInfo.setting_schema;

      const filterd_blocks = fetchedInfo.setting_schema.blocks.filter(
        (block) => {
          if (
            block.fieldMapping.latitude.selected_field &&
            block.fieldMapping.longitude.selected_field
          ) {
            return true;
          } else {
            return false;
          }
        }
      );
      console.log({ filterd_blocks });

      filterd_blocks.forEach((block, block_index) => {
        if (
          block.fieldMapping.latitude.selected_field?.api_name &&
          block.fieldMapping.longitude.selected_field?.api_name
        ) {
          //get address
          const address = Object.values(block.fieldMapping).map((field) => {
            if (
              field.selected_field &&
              field.id !== "latitude" &&
              field.id !== "longitude"
            ) {
              return field.selected_field.api_name;
            }
            return null;
          });

          block_settings[block_index + 1] = {
            Block_Id: block_index + 1,
            Block_Name: block.address_block_name,
            Lat: block.fieldMapping.latitude.selected_field.api_name,
            Lan: block.fieldMapping.longitude.selected_field.api_name,
            Address: address,
            Records: [],
          };
        }
      });

      // ZOHO.CRM.API.getAllRecords({
      //   Entity: moduleInfo.module_name,
      //   per_page: 200,
      //   page: 1,
      // });
      // `${dataCenterUrl}/crm/v2/functions/easyaddressautocomplete__getmodulerecords/actions/execute?auth_type=apikey&zapikey=${zapiKey}`,
      await ZOHO.CRM.HTTP.post({
        url: `${dataCenterUrl}/crm/v2/functions/easyaddressautocomplete__getmodulerecords/actions/execute?auth_type=apikey&zapikey=${zapiKey}`,
        body: {
          moduleInfo,
          fetchedInfo,
        },
      })
        .then((data) => {
          const fields = JSON.parse(data);
          // const fields = data;
          fields.forEach((field, index) => {
            const block_settings_info = Object.values(block_settings);

            //check in which block this field exists
            block_settings_info.forEach((block) => {
              if (field[block.Lat] && field[block.Lan]) {
                const address = block.Address.flatMap(
                  (field_api_name) => field[field_api_name] || []
                ).join(",");
                let Name;
                if (
                  moduleInfo.module_name === "Leads" ||
                  moduleInfo.module_name === "Contacts"
                ) {
                  Name = "Full_Name";
                } else if (moduleInfo.module_name === "Accounts") {
                  Name = "Account_Name";
                } else if (moduleInfo.module_name === "Deals") {
                  Name = "Deal_Name";
                } else if (moduleInfo.module_name === "Products") {
                  Name = "Product_Name";
                } else if (moduleInfo.module_name === "Campaigns") {
                  Name = "Campaign_Name";
                } else if (moduleInfo.module_name === "Vendors") {
                  Name = "Vendor_Name";
                } else {
                  Name = "Name";
                }
                block_settings[block.Block_Id].Records.push({
                  index: block_settings[block.Block_Id].Records.length + 1,
                  id: field.id,
                  Name: field[Name],
                  Address: address,
                  Lat: Number(field[block.Lat]),
                  Lan: Number(field[block.Lan]),
                });
              }
            });
          });
        })
        .then(() => {
          console.log({ block_settings });
          setBlockData(block_settings);
          setLoading(false);
        })
        .catch((err) => {
          console.log({ err });
          setLoading(false);
        });
    };
    fetchData();
  }, []);
  if (loading)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <Loader />
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
    );

  if (blockData && Object.keys(blockData).length === 0)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "90vh",
        }}
      >
        <Box
          sx={{
            height: "270px",
            pb: 0,
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              display: "block",
              width: "200px",
              margin: "0 auto",
              objectFit: "container",
            }}
            src={latLongNotFound}
            alt="setting Page PNG"
          />
          <Typography
            sx={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "150%",
              textAlign: "center",
              letterSpacing: "0.15px",
              color: "rgba(0, 0, 0, 0.6)",
              maxWidth: "400px",
            }}
          >
            The map preview is not currently available, but it can be generated
            by adding your latitude and longitude field.
          </Typography>
        </Box>
      </Box>
    );

  return (
    <Box p={1}>
      <GoogleMap
        blockData={blockData}
        apiKey={apiKey}
        module={moduleInfo.module_name}
        dataCenterUrl={dataCenterUrl}
        listViewWithoutRecordInfo={listViewWithoutRecordInfo}
      />
    </Box>
  );
}
