import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Autocomplete } from "@react-google-maps/api";
import Geocode from "react-geocode";
import { GoogleMap, MarkerF } from "@react-google-maps/api";

import latLongNotFound from "../latLongNotFound.png";

const containerStyle = {
  width: "100%",
  height: "270px",
};

export default function DetailView({
  moduleInfo,
  fetchedInfo,
  ZOHO,
  saveButtonLabel = "Save",
}) {
  function customTrim(str, chars) {
    let start = 0;
    let end = str.length;

    while (start < end && chars.includes(str[start])) {
      start++;
    }

    while (end > start && chars.includes(str[end - 1])) {
      end--;
    }

    return str.slice(start, end);
  }
  const [autocomplete, setAutocomplete] = useState({});
  const [loading, setLoading] = useState(false);
  const [address_components_values, set_address_components_values] = useState(
    {}
  );
  const [module_records, set_module_records] = useState({});
  const [map, setMap] = React.useState(null);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const marker_one = useRef();

  let selectedValues = {};

  useEffect(() => {
    setLoading(true);
    ZOHO.CRM.API.getRecord({
      Entity: moduleInfo.module_name,
      RecordID: moduleInfo.data.EntityId[0],
    })
      .then(function (data) {
        set_module_records(data.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <Box
      component="form"
      sx={{ mx: "auto", p: 2, background: "#F5F5F5" }}
      noValidate
      autoComplete="off"
    >
      <Grid container sx={{ pb: "55px" }} spacing={2}>
        {fetchedInfo?.setting_schema?.blocks.map((field, blockIndex) => {
          const { address_block_name, fieldMapping } = field;
          const {
            address_1,
            address_2,
            state,
            city,
            zip,
            country,
            latitude,
            longitude,
          } = fieldMapping;
          const partsOfAddress = [
            address_1,
            address_2,
            state,
            city,
            zip,
            country,
            latitude,
            longitude,
          ];

          let fullAddressParts = [];
          [
            "address_1",
            "address_2",
            "city",
            "state",
            "zip",
            "country",
            "latitude",
            "longitude",
          ].forEach((partKey) => {
            if (
              fieldMapping?.[partKey]?.selected_field?.api_name &&
              module_records?.[
                fieldMapping?.[partKey]?.selected_field?.api_name
              ] &&
              partKey !== "latitude" &&
              partKey !== "longitude"
            ) {
              fullAddressParts.push(
                module_records?.[
                  fieldMapping?.[partKey]?.selected_field?.api_name
                ]
              );
            }
          });

          return (
            <Grid
              item
              key={blockIndex}
              xs={fetchedInfo.setting_schema.blocks.length > 1 ? 6 : 12}
            >
              <Box
                sx={{
                  height: "100%",
                  background: "#fff",
                  p: 1.5,
                  borderRadius: "12px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Lato",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "20px",
                        lineHeight: "160%",
                        letterSpacing: "0.15px",
                        color: "rgba(0, 0, 0, 0.87)",
                        mb: "8px",
                      }}
                    >
                      {address_block_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={{
                        // fields: [
                        //   "address_components",
                        //   "place_id",
                        //   "geometry",
                        //   "formatted_address",
                        // ],
                        // types: ["geocode"],
                        ...(fetchedInfo.setting_schema.country_restrictions
                          .length > 0
                          ? {
                              componentRestrictions: {
                                country:
                                  fetchedInfo.setting_schema.country_restrictions.map(
                                    (country) => country.short
                                  ),
                              },
                            }
                          : {}),
                      }}
                      onLoad={(autocomplete) => {
                        // setAutocomplete(autocomplete);
                        setAutocomplete((prev) => ({
                          ...prev,
                          [blockIndex]: autocomplete,
                        }));
                      }}
                      onPlaceChanged={(place) => {
                        const values = {};

                        autocomplete[blockIndex]
                          .getPlace()
                          .address_components.forEach((address_component) => {
                            address_component.types.forEach((type) => {
                              if (type !== "political") {
                                values[type] = {
                                  long_name: address_component.long_name,
                                  short_name: address_component.short_name,
                                };
                              }
                            });
                          });
                        values.location = {
                          latitude: autocomplete[blockIndex]
                            .getPlace()
                            .geometry.location.lat(),
                          longitude: autocomplete[blockIndex]
                            .getPlace()
                            .geometry.location.lng(),
                        };

                        set_address_components_values((prev) => ({
                          ...prev,
                          [`block-${blockIndex}`]: {
                            ...values,
                            formatted_address:
                              autocomplete[blockIndex].getPlace()
                                .formatted_address,
                          },
                        }));
                      }}
                    >
                      <TextField
                        inputProps={{ autocomplete: "off" }}
                        value={
                          address_components_values?.[`block-${blockIndex}`]
                            ?.formatted_address ?? fullAddressParts.join(", ")
                        }
                        size="small"
                        fullWidth
                        sx={{
                          maxWidth: "100%",
                          background: "#ffffff",
                        }}
                        id="outlined-required"
                        label="Enter Address"
                        onChange={(e) => {
                          set_address_components_values((prev) => ({
                            ...prev,
                            [`block-${blockIndex}`]: {
                              ...prev[`block-${blockIndex}`],
                              formatted_address: e.target.value,
                            },
                          }));
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationOnOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Autocomplete>
                  </Grid>
                  {partsOfAddress.map((singleAddress, index) => (
                    <React.Fragment key={index}>
                      {/* if user selects field and there is google response */}
                      {/* else show a disabled text field */}
                      {singleAddress.selected_field &&
                      singleAddress.google_response ? (
                        <Grid item xs={index === 0 || index === 1 ? 12 : 6}>
                          {!address_components_values[
                            `block-${blockIndex}`
                          ] && (
                            <TextField
                              inputProps={{ autocomplete: "off" }}
                              sx={{ width: "100%" }}
                              size="small"
                              id="outlined-disabled"
                              label={singleAddress.label}
                              value={
                                module_records[
                                  singleAddress.selected_field.api_name
                                ]
                              }
                              disabled
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                            />
                          )}
                          {address_components_values[`block-${blockIndex}`] &&
                            (() => {
                              let str = singleAddress.google_response;
                              const regex = /\${[\w._]+}/g;
                              const matches = str.match(regex);

                              matches?.forEach((match) => {
                                const regex = /(\w+)\.(\w+)/;
                                const matches = match.match(regex);
                                const first_word = matches[1];
                                const second_word = matches[2];

                                const value =
                                  address_components_values[
                                    `block-${blockIndex}`
                                  ]?.[first_word]?.[second_word] || "";

                                str = str.replace(
                                  new RegExp(`\\${match}`, "g"),
                                  value
                                );
                                if (
                                  singleAddress.id != "longitude" &&
                                  singleAddress.id != "latitude"
                                ) {
                                  str = customTrim(str, "-, ");
                                }

                                selectedValues[
                                  singleAddress.selected_field.api_name
                                ] = str;
                              });
                              return (
                                <TextField
                                  inputProps={{ autocomplete: "off" }}
                                  outlined
                                  disabled
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  label={singleAddress.label}
                                  value={str}
                                  size="small"
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              );
                            })()}
                        </Grid>
                      ) : (
                        <Grid item xs={index === 0 || index === 1 ? 12 : 6}>
                          <TextField
                            inputProps={{ autocomplete: "off" }}
                            sx={{
                              width: "100%",
                              background: "#eeeeee",
                              borderRadius: "4px",
                            }}
                            size="small"
                            id="outlined-disabled"
                            label={singleAddress.label}
                            value={null}
                            disabled
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                          />
                        </Grid>
                      )}
                    </React.Fragment>
                  ))}
                  {/* render google map */}
                  {{ ...module_records, ...selectedValues }[
                    latitude?.selected_field?.api_name
                  ] &&
                    { ...module_records, ...selectedValues }[
                      longitude?.selected_field?.api_name
                    ] && (
                      <Grid item xs={12}>
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={
                            address_components_values[`block-${blockIndex}`]
                              ? {
                                  lat: address_components_values[
                                    `block-${blockIndex}`
                                  ]?.location?.latitude,
                                  lng: address_components_values[
                                    `block-${blockIndex}`
                                  ]?.location?.longitude,
                                }
                              : {
                                  lat: Number(
                                    { ...module_records, ...selectedValues }[
                                      latitude?.selected_field?.api_name
                                    ]
                                  ),
                                  lng: Number(
                                    { ...module_records, ...selectedValues }[
                                      longitude?.selected_field?.api_name
                                    ]
                                  ),
                                }
                          }
                          zoom={16}
                        >
                          <MarkerF
                            position={
                              address_components_values[`block-${blockIndex}`]
                                ? {
                                    lat: address_components_values[
                                      `block-${blockIndex}`
                                    ]?.location?.latitude,
                                    lng: address_components_values[
                                      `block-${blockIndex}`
                                    ]?.location?.longitude,
                                  }
                                : {
                                    lat: Number(
                                      { ...module_records, ...selectedValues }[
                                        latitude?.selected_field?.api_name
                                      ]
                                    ),
                                    lng: Number(
                                      { ...module_records, ...selectedValues }[
                                        longitude?.selected_field?.api_name
                                      ]
                                    ),
                                  }
                            }
                            draggable={true}
                            ref={marker_one}
                            zIndex={1000}
                            onDragEnd={(e) => {
                              const latitude = e.latLng.lat();
                              const longitude = e.latLng.lng();

                              const block_name = `block-${blockIndex}`;

                              Geocode.fromLatLng(latitude, longitude).then(
                                (response) => {
                                  const result = response.results[0];

                                  const values = {};
                                  result.address_components.forEach(
                                    (address_component) => {
                                      address_component.types.forEach(
                                        (type) => {
                                          if (type !== "political") {
                                            values[type] = {
                                              long_name:
                                                address_component.long_name,
                                              short_name:
                                                address_component.short_name,
                                            };
                                          }
                                        }
                                      );
                                    }
                                  );

                                  values.location = {
                                    latitude,
                                    longitude,
                                  };
                                  set_address_components_values((prev) => ({
                                    ...prev,
                                    [block_name]: {
                                      ...values,
                                      formatted_address:
                                        response.results[0].formatted_address,
                                    },
                                  }));
                                },
                                (error) => {
                                  console.error(error);
                                }
                              );
                            }}
                            onUnmount={onUnmount}
                          />
                        </GoogleMap>
                      </Grid>
                    )}
                  {/* render placeholder image if there is no latitude and longitude */}
                  {(!{ ...module_records, ...selectedValues }[
                    latitude?.selected_field?.api_name
                  ] ||
                    !{ ...module_records, ...selectedValues }[
                      longitude?.selected_field?.api_name
                    ]) && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          height: "270px",
                          pb: 0,
                          background: "#fff",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{
                            display: "block",
                            width: "200px",
                            margin: "0 auto",
                            objectFit: "container",
                          }}
                          src={latLongNotFound}
                          alt="setting Page PNG"
                        />
                        <Typography
                          sx={{
                            fontFamily: "Lato",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "150%",
                            textAlign: "center",
                            letterSpacing: "0.15px",
                            color: "rgba(0, 0, 0, 0.6)",
                            maxWidth: "400px",
                            width: "100%",
                          }}
                        >
                          The map preview is not currently available, but it can
                          be generated by adding your latitude and longitude
                          field.
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
          );
        })}
        <Box
          sx={{
            bgcolor: "#FFFFFF",
            // bgcolor: "red",
            zIndex: 999999,
            position: "fixed",
            bottom: 0,
            right: 0,
            left: 0,
            p: 1.5,
            boxShadow: "0px 0px 0px 1px #E0E0E0",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box>
            <Button
              size="small"
              variant="outlined"
              sx={{ mr: "12px" }}
              onClick={() => {
                ZOHO.CRM.UI.Popup.close().then(function (data) {});
              }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={async () => {
                var config = {
                  Entity: moduleInfo.module_name,
                  APIData: {
                    id: moduleInfo.data.EntityId[0],
                    ...selectedValues,
                  },
                  Trigger: ["workflow"],
                };

                ZOHO.CRM.API.updateRecord(config)
                  .then(async function (data) {
                    ZOHO.CRM.UI.Popup.closeReload().then(function (data) {});
                  })
                  .catch((err) => {
                    // alert(err);
                  });
              }}
            >
              {saveButtonLabel}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}
