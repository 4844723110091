import * as React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Backdrop from "@mui/material/Backdrop";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../loader/Loader";
import axios from "axios";
import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Modal,
  TablePagination,
  TextField,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(25, 118, 210, 0.12)",
    color: "black",
    padding: "7px 5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0 5px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  minHeight: 200,
  transform: "translate(-50%, -50%)",
  minWidth: 660,
  bgcolor: "background.paper",
  // borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

const ZOHO = window.ZOHO;

export default function HistoricalData({
  orgId,
  zohoApiKey,
  dataCenterUrl,
  zapiKey,
}) {
  const [loading, setLoading] = useState(false);
  const [handleSubmitLoading, setHandleSubmitLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [cronList, setCronList] = useState([]);
  const [individualCron, setIndividualCron] = useState({});
  const [fetchedBlocks, setFetchedBlocks] = useState([]);
  const [availableModules, setAvailableModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState();
  const [customViews, setCustomViews] = useState([]);
  const [selectedViews, setSelectedViews] = useState();
  const [customViewFetchLoading, setCustomViewFetchLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = (module) => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (type, data) => {
    setIndividualCron({ type: type, data: data });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setIndividualCron({});
    setOpenModal(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const visibleRows = React.useMemo(
    () => cronList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [cronList, page, rowsPerPage]
  );

  useEffect(() => {
    if (!selectedModule) {
      return;
    }
    setCustomViewFetchLoading(true);
    ZOHO.CRM.META.getCustomViews({ Entity: selectedModule }).then(function (
      data
    ) {
      setCustomViews(data?.custom_views);
      setCustomViewFetchLoading(false);
    });
  }, [selectedModule]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await axios.request({
        url: `https://api.easy-pluginz.com/admin/v2/db/planetscale/clients/settings`,
        method: "GET",
        headers: {
          orgid: orgId, // Org ID
          apikey: zohoApiKey, // API KEy
          connname: "easyaddressautocomplete", // Conn Name
        },
      });

      const filteredData = response.data?.data?.map((blockData) => ({
        ...blockData,
        setting_schema: JSON.parse(blockData.setting_schema),
      }));

      //

      setFetchedBlocks(filteredData);

      const resp = await axios.request({
        url: `https://api.v1.admin.easy-pluginz.com/utils/cronjobs`,
        method: "GET",
        headers: {
          orgid: orgId, // Org ID
          apikey: zohoApiKey, // API KEy
          connname: "easyaddressautocomplete", // Conn Name
        },
      });

      setPage(0);
      setRowsPerPage(10);

      let result = resp?.data?.data || [];

      setCronList(result);

      let filteredList = result
        .filter((item) => item.status === "In Progress")
        ?.map((el) => el.req_body.blockData.module_name);

      let availableModulesForCron = filteredData
        ?.map((item) => item.module_name)
        ?.filter((element) => !filteredList.includes(element));
      // let runningModuleList = [];

      // filteredList.forEach((el) => {
      //   if (el.req_body.blockData.module_name) {
      //     runningModuleList.push(el.req_body.blockData.module_name);
      //   }
      // });
      setAvailableModules(availableModulesForCron);

      setLoading(false);
    };

    fetchData();
  }, [toggle]);

  const handleSubmit = async (module, cv) => {
    let blockData = fetchedBlocks.find((el) => el.module_name === module);

    try {
      setHandleSubmitLoading(true);
      const resp = await axios.request({
        url: `https://api.v1.admin.easy-pluginz.com/utils/cronjobs`,
        method: "POST",
        headers: {
          orgid: orgId, // Org ID
          apikey: zohoApiKey, // API KEy
          connname: "easyaddressautocomplete", // Conn Name
        },
        data: {
          reqUrl: encodeURIComponent(
            `${dataCenterUrl}/crm/v2/functions/easyaddressautocomplete__executecron/actions/execute?auth_type=apikey&zapikey=${zapiKey}`
          ),
          reqParams: {},
          reqHeaders: {},
          reqBody: {
            blockData: blockData,
            cv_id: { name: cv.display_value, id: cv.id },
          },
          per_page: 50,
          reqType: "GET",
        },
      });

      setHandleSubmitLoading(false);
      handleClose();
      setToggle(!toggle);
    } catch (error) {}
  };

  const handleCronUpdate = async () => {
    //
    // return;
    try {
      setHandleSubmitLoading(true);
      if (individualCron.type === "stop") {
        let reqBody = {
          url: `https://api.v1.admin.easy-pluginz.com/utils/cronjobs`,
          method: "PUT",
          headers: {
            orgid: orgId, // Org ID
            apikey: zohoApiKey, // API KEy
            connname: "easyaddressautocomplete", // Conn Name
          },
          data: {
            settingId: individualCron?.data?.setting_id,
            settingStatus: "Stopped",
          },
        };
        const resp = await axios.request(reqBody);

        setHandleSubmitLoading(false);
        handleCloseModal();
        setToggle(!toggle);
      } else {
        let reqBody = {
          url: `https://api.v1.admin.easy-pluginz.com/utils/cronjobs/${individualCron?.data?.setting_id}`,
          method: "DELETE",
          headers: {
            orgid: orgId, // Org ID
            apikey: zohoApiKey, // API KEy
            connname: "easyaddressautocomplete", // Conn Name
          },
        };

        const resp = await axios.request(reqBody);

        setHandleSubmitLoading(false);
        handleCloseModal();
        setToggle(!toggle);
      }
    } catch (error) {}
  };

  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Loader />
      </Backdrop>
    );
  } else {
    return (
      <Box sx={{ m: 5, mt: 1 }}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box>
              <Typography sx={{ fontWeight: "bold", my: 1, mb: 3 }}>
                Please Select Modules and Custom View
              </Typography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={availableModules}
                getOptionLabel={(option) => option}
                onChange={(_, data) => {
                  if (data) {
                    setSelectedModule(data);
                  } else {
                    setSelectedModule();
                    setCustomViews([]);
                    setSelectedViews();
                  }
                }}
                sx={{ width: 300, mb: 2 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Modules"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                loading={customViewFetchLoading}
                options={customViews}
                getOptionLabel={(option) => option?.display_value}
                onChange={(_, data) => {
                  if (data) {
                    setSelectedViews(data);
                  } else {
                    setSelectedViews();
                  }
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Custom Views"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  sx={{ mr: 2, width: 120 }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!selectedViews?.id || handleSubmitLoading}
                  onClick={() => handleSubmit(selectedModule, selectedViews)}
                  variant="contained"
                  sx={{ width: 120 }}
                >
                  {handleSubmitLoading ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      loading
                      <CircularProgress
                        sx={{ color: "white", ml: 1 }}
                        size={17}
                      />{" "}
                    </Box>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
          {" "}
          <Button
            onClick={handleOpen}
            variant="contained"
            size="medium"
            sx={{
              fontWeight: 500,
              fontFamily: "Lato",
              textTransform: "capitalize",
            }}
          >
            <AddIcon sx={{ mr: "5px", fontSize: "16px" }} />
            Bulk Process
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Created Date</StyledTableCell>
                <StyledTableCell align="left">Module Name</StyledTableCell>
                <StyledTableCell align="left">Custom View Name</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                {/* <StyledTableCell align="center">Comment</StyledTableCell> */}
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cronList.length > 0 ? (
                visibleRows.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">
                      {row?.created_at
                        ? dayjs(row?.created_at).format("MM-DD-YYYY HH:mm")
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.req_body?.blockData?.module_name}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.req_body?.cv_id?.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {" "}
                      {row?.status === "Completed" && (
                        <Chip
                          sx={{
                            fontWeight: 500,
                            width: 95,
                            height: 25,
                            bgcolor: "rgba(102, 187, 106, 0.4)",
                            color: "#036e07",
                          }}
                          label={row?.status}
                        />
                      )}
                      {row?.status === "In Progress" && (
                        <Chip
                          sx={{
                            fontWeight: 500,
                            width: 95,
                            height: 25,
                            bgcolor: "rgba(255, 249, 196, 0.8)",
                            color: "black",
                          }}
                          label={row?.status}
                        />
                      )}
                      {!["In Progress", "Completed"].includes(row?.status) && (
                        <Chip
                          sx={{
                            fontWeight: 500,
                            width: 95,
                            height: 25,
                            bgcolor: "rgba(211, 47, 47, 0.4)",
                            color: "black",
                          }}
                          label={row?.status}
                        />
                      )}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">
                      {row?.record_count === -1 &&
                        row?.status === "In Progress" &&
                        "Analyzing Data"}
                      {row?.status === "Completed" &&
                        (
                          parseFloat(
                            dayjs(row?.modified_at).diff(
                              dayjs(row?.created_at),
                              "m",
                              true
                            )
                          ) +
                          row.record_count / 50
                        ).toFixed(2)}
                    </StyledTableCell> */}
                    <StyledTableCell align="center">
                      {row?.status === "In Progress" && (
                        <Tooltip title="Stop">
                          <StopCircleIcon
                            onClick={() => handleOpenModal("stop", row)}
                            sx={{
                              cursor: "pointer",
                              color: "rgba(237, 108, 2, 1)",
                            }}
                          />
                        </Tooltip>
                      )}
                      {row?.status !== "In Progress" && (
                        <Tooltip title="Delete">
                          <DeleteOutlineIcon
                            onClick={() => handleOpenModal("delete", row)}
                            sx={{
                              cursor: "pointer",
                              color: "rgba(211, 47, 47, 1)",
                            }}
                          />
                        </Tooltip>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    sx={{ fontWeight: "bold" }}
                    colSpan={5}
                    align="center"
                  >
                    No Data Available
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={cronList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                bgcolor: "white",
                borderRadius: 5,
              }}
            >
              {" "}
              <Typography sx={{ mt: 4, fontWeight: "bold", fontSize: 20 }}>
                Do you want to{" "}
                {individualCron.type === "stop" ? "stop" : "delete"} this log?
              </Typography>
              <Box
                sx={{
                  mt: 3,
                  ml: "1rem",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                <Button
                  sx={{ width: 120 }}
                  variant="outlined"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>

                <Button
                  disabled={handleSubmitLoading}
                  sx={{ width: 120 }}
                  variant="contained"
                  onClick={handleCronUpdate}
                >
                  {handleSubmitLoading ? (
                    <CircularProgress
                      sx={{ color: "white", ml: 1 }}
                      size={16}
                    />
                  ) : (
                    "Proceed"
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    );
  }
}
