import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Autocomplete as MuiAutocomplete } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loader from "../../components/loader/Loader";
import countries from "./countries.json";

const ZOHO = window.ZOHO;

export default function ContentOfSlider1({
  handleAddressComponentsValue,
  handleSelectedModule,
  handleFetchedModules,
  handleCountryRestrictions,
  modules,
  fetchedModules,
  selectedModule,
  formatted_address,
  restrictedCountries,
  existingData,
  api_key,
  for_settings,
}) {
  const [loadingForFetchingFields, setLoadingForFetchingFields] =
    useState(false);
  const [autocomplete, setAutocomplete] = useState(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: api_key, // ,
    libraries: ["places"],
    // ...otherOptions
  });

  return (
    <Box
      sx={{
        mt: "42px",
        mx: "auto",
        maxWidth: "35rem",
        overflow: "hidden",
      }}
    >
      <Typography
        sx={{
          maxWidth: "10rem",
          mb: "8px",
          color: "rgba(0, 0, 0, 1)",
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "32px",
          letterSpacing: ".15px",
        }}
      >
        Address Settings
      </Typography>
      <Typography
        sx={{
          //height: "72px",
          maxWidth: "100%",
          marginBottom: "38px",
          lineHeight: "1.5rem",
          fontWeight: 400,
          fontSize: "1rem",
          letterSpacing: ".15px",
          color: "rgba(0, 0, 0, 0.6)",
          // backgroundColor: "gray",
        }}
      >
        Please provide Module Name, Related Countries (Max 5) and a Sample
        Address to vrify the Address Components.
      </Typography>

      <MuiAutocomplete
        sx={{
          maxWidth: "100%",
          mb: 2.5,
        }}
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
        popupIcon={<ExpandMoreIcon />}
        label="modal"
        options={modules}
        getOptionLabel={(option) => option.plural_label}
        id="combo-box-demo"
        value={selectedModule}
        clearIcon={false}
        disabled={(!for_settings || selectedModule) && true}
        onChange={(event, newValue) => {
          if (!newValue) return;
          handleSelectedModule({
            api_name: newValue.api_name,
            plural_label: newValue.plural_label,
          });

          //if this module is found in the fetchedModules then take the fields from there
          if (fetchedModules[newValue.api_name]) {
            return handleFetchedModules({
              [newValue.api_name]: fetchedModules[newValue.api_name],
            });
          }
          setLoadingForFetchingFields(true);

          try {
            ZOHO.CRM.META.getFields({ Entity: newValue.api_name }).then(
              function (data) {
                const filteredFields = data.fields.filter((field) => {
                  if (field.data_type === "text") {
                    return true;
                  }
                });

                handleFetchedModules({
                  [newValue.api_name]: filteredFields,
                });
              }
            );
          } catch (err) {
            // alert(err);
          } finally {
            setLoadingForFetchingFields(false);
          }
        }}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            fullWidth
            id="outlined-required"
            label="Module"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingForFetchingFields ? <Loader /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
      <MuiAutocomplete
        //freeSolo
        multiple
        sx={{
          maxWidth: "100%",
          mb: 2.5,
        }}
        id="tags-standard"
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
        popupIcon={<ExpandMoreIcon />}
        options={countries}
        disableCloseOnSelect={true}
        getOptionLabel={(option) => option.long}
        value={countries.filter((country) => {
          if (restrictedCountries.find((c) => c.short === country.short)) {
            return true;
          } else {
            return false;
          }
        })}
        onChange={(event, newValue) => {
          handleCountryRestrictions(newValue);
        }}
        renderInput={(params) => (
          <TextField
            size="small"
            error={restrictedCountries?.length > 5 ? "true" : ""}
            helperText={
              restrictedCountries?.length > 5
                ? "You can't select more than 5 Coutries"
                : ""
            }
            {...params}
            fullWidth
            variant="outlined"
            label="Country"
            //placeholder="Select Country"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingForFetchingFields ? <Loader /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
      {/* {restrictedCountries?.length > 5 && (
        <p>You can't select more than 5 Coutries</p>
      )} */}

      {isLoaded && (
        <Autocomplete
          options={{
            // fields: [
            //   "address_components",
            //   "place_id",
            //   "geometry",
            //   "formatted_address",
            // ],
            // types: ["geocode"],
            ...(restrictedCountries.length > 0
              ? {
                  componentRestrictions: {
                    country: restrictedCountries.map(
                      (country) => country.short
                    ),
                  },
                }
              : {}),
          }}
          onLoad={(autocomplete) => {
            setAutocomplete(autocomplete);
          }}
          onPlaceChanged={() => {
            const values = {};

            autocomplete
              .getPlace()
              .address_components?.forEach((address_component) => {
                address_component.types.forEach((type) => {
                  if (type !== "political") {
                    values[type] = {
                      long_name: address_component.long_name,
                      short_name: address_component.short_name,
                    };
                  }
                });
              });

            values.location = {
              latitude: autocomplete.getPlace().geometry.location.lat(),
              longitude: autocomplete.getPlace().geometry.location.lng(),
            };

            handleAddressComponentsValue({
              componentValues: values,
              formatted_address: autocomplete.getPlace().formatted_address,
            });
          }}
        >
          <TextField
            defaultValue={formatted_address}
            size="small"
            fullWidth
            sx={{
              maxWidth: "100%",
              mb: 3,
            }}
            id="outlined-required"
            label="Sample Address"
            inputProps={{ autocomplete: "off" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </Autocomplete>
      )}
    </Box>
  );
}
